.rects-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.rect {
  width: 50px;
  height: 50px;
  background-color: #3572EF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  transition: background-color 0.5s;
}

.rect.highlight {
  background-color: rgb(255, 0, 166);
}
.rect.mid {
  background-color: rgb(115, 0, 165);
}

.rect.target {
  background-color: rgb(0, 149, 27);
}
