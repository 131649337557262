.panel-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.half-height {
  height: 35vh;
  /* padding: 10px 0; */
  overflow-y: auto;
}