.grid {
  margin: 10px;
}

.node-type{
  display: flex;
  width: fit-content;
  margin: 10px;
  padding: 0 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  
  /* background-color: #5a43c3; */
}

.node-type .node{
  margin: 0;
}

.node-type p{
  padding: 0 10px;
  margin: 0;
}
