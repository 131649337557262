/* Styles for the queue visualizer container */
.queue-visualizer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
}

/* Styles for the queue stack */
.queue {
    display: flex;
    padding: 10px 15px 10px 10px;
    margin: 20px auto 20px auto;
    border: #886281 solid 1px;
    border-radius: 10px;
    min-width: 500px;
    height: 50px;
    margin-top: 150px;
}

.pointer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
}

.pointer.front {
    position: absolute;
    margin-top: -80px;
    margin-left: 30px;
    color: #1640D6;
}

.pointer.rear {
    margin-left: -85px;
    padding: 0 15px;
    margin-top: 60px;
    color: #337357;
}

.element {
    width: 100px;
    height: 50px;
    background-color: #DA0C81;
    border-radius: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    font-size: 20px;
    animation: slideIn-enqueue 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.dequeued {
    animation: slideOut-dequeue 0.5s cubic-bezier(0, 0, 0.2, 1);
}



/* Additional keyframe animations */
@keyframes slideIn-enqueue {
    from {
        transform: translateX(200px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        background-color: #940B92;
        opacity: 1;
    }
}

@keyframes slideOut-dequeue {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(-200px);
        background-color: #610C9F;
    }
}