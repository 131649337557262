.App {
  text-align: center;
}

h1 {
  text-align: center;
  font-family: 'SilkscreenNormal';
  font-size: 36px;
  margin-top: 50px;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 15px;
}

ul,
li {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  list-style: none;
}

.list {
  width: 80%;
  margin: 0 10%;
  clear: both;
  display: flex;
  justify-items: center;
}

a {
  text-decoration: none;
  color: black;
}

p {
  text-align: left;
}

@media (min-width: 540px) {

  /* 540-768px */
  h1 {
    font-size: 50px;
  }
}

/* basic components */

button,
select,
input {
  display: inline-block;
  min-width: 120px;
  margin: 10px;
  padding: 0.8em 1.5em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

button {
  color: #333;
  background-color: #f0edff;
  border: 1px solid #d5d0e0;
}

button:hover {
  cursor: pointer;
  background-color: #eae5ff;
  color: #222;
}

select {
  padding: .8em 4em .8em 1.5em;
  appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, #5a43c3 50%),
    linear-gradient(135deg, #5a43c3 50%, transparent 50%),
    linear-gradient(to right, #f0edff, #f0edff);
  background-position:
    calc(100% - 1.5em) calc(1.3em + 2px),
    calc(100% - 1.2em) calc(1.3em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    3em 4em;
  background-repeat: no-repeat;
  cursor: pointer;
}

.visualize-btn {
  background-color: #5a43c3;
  color: #fff;
}

.visualize-btn:hover {
  background-color: #493db8; 
  color: #fff;

}

.reset-btn {
  background-color: #c34343;
  color: #fff;
}

.reset-btn:hover {
  background-color: #b83d3d; 
  color: #fff;
}

.reset-btn-2 {
  color: #ac2222;
  background-color: #fff7f7;
  border: 1px solid #e4d0d0;
}

.reset-btn-2:hover {
  color: #8d1010;
  background-color: #fffafa;
  border: 1px solid #e77373;
}

/* menu styles */
.menu {
  display: flex;
  padding: 10px 60px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}

.controls {
  display: flex;
  gap: 10px;
}

/* nabar styles */
.navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 60px;
  margin-bottom: 10px;
  align-items: center;
  background: white;
  border-bottom: 1px solid #e0e0e0;
  flex-shrink: 0;
  line-height: 1.7em;
  /* width: 100%; */
}

.navbar .arrow {
  margin: 10px;
  height: 0.9em;
  color: #58505f;
}

.current-page {
  margin: 0 5px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #58505f;
}

.navbar .home-btn {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  width: 5em;
  padding: 0 10px;
}

.home-btn a {
  color: #7d47e9;
}

.home-btn a:hover {
  color: #5a43c3;
  /* text-shadow: #61dafba2 1px 1px 2px; */
}

.navbar .nav-action:hover{
color: #0000009a;
}

.navbar button:hover{
  background-color: transparent;
}

/* home styles */
.container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px 60px;
  /* min-width: 1024px; */
  max-width: 1240px;
}

.dashboard-section-header {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  align-items: baseline;
}

/* Home cards */
.cards-grid {
  display: grid;
  gap: var(--dashboard-column-gap, 30px);
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 10px;
}

.algo-card {
  border: .5px solid #ddd !important;
  border-radius: .5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px;
  min-height: 183px;
  min-width: 300px;
  background-color: #FFF;
  justify-content: space-between;
  align-items: flex-start;
  transition: 0.5s;
}

.algo-card:hover {
  scale: 1.025;
}

.card-logo {
  position: absolute;
  right: -10%;
  bottom: 0%;
  height: 85%;
  opacity: .1;
  pointer-events: none;
}

.card-tags {
  display: flex;
  flex-flow: wrap;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: baseline;
  gap: 5px;
  max-height: 70px;
  overflow: hidden;
}

.card-tag {
  display: inline-block;
  padding: 4px 12px;
  width: fit-content !important;
  background-color: #7469B6;
  color: #fff;
  border-radius: 4px;
  line-height: 1.5em;
}

.card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
}

.algo-card .card-title {
  font-size: 20px;
  text-align: left;
  padding-right: 5px;
  line-height: 1.5em;
}

@media (max-width: 1024px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .algo-card {
    min-height: 270px;
  }
}

@media (max-width: 768px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .algo-card {
    min-height: 183px;
  }
}

.hero-container {
  /* background-color: #C4B0FF; */
  width: 100%;
  /* padding-bottom: 60px; */
  /* padding-bottom: 30px; */
}

.home22-intro:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 1px;
  display: block;
  background: #6E6BFE;
  border-radius: 50%;
  box-shadow: 0 0 1000px 350px #6E6BFE;
  z-index: -1;
  right: 5%;
  top: 10%;
  transform: translate(-80%, -80%);
  opacity: .2;
}

.home22-intro:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 1px;
  display: block;
  background: #f175ff;
  border-radius: 50%;
  box-shadow: 0 0 1000px 350px #f175ff;
  z-index: -1;
  left: 0%;
  top: 10%;
  transform: translate(80%, -80%);
  opacity: .2;
}

/* Home nav */
.nav-logo {
  /* padding: 24px 0; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: #ddd 1px solid;
  background-color: #fff;
  color: #7469B6;
  color: #fff;
  background-color: #9385e0;
  gap: 8px;
}

.logo-text {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

.hero-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  padding: 80px 20px;
}

.hero-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  max-width: 840px;
  margin-top: 20px;
  text-align: center;
}

.hero-text-content-h1 {
  margin: 0 auto;
  color: #0d0c22;
  font-family: "Source Serif Pro", serif;
  font-size: 72px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 1.25;
  text-align: center;
}

.hero-text-content-h2 {
  max-width: 565px;
  /* margin: 24px auto; */
  color: #3d3d4e;
  font-size: 18px;
  font-style: normal;
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 32px;
}

/* Search box */

.search-input-hero {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.search-input-hero .search-icon {
  position: absolute;
  top: 38px;
  left: -5px;
  opacity: 0.5;
}

.search-input-hero label {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  display: block;
  margin-bottom: 10px;
  color: #0d0c22;
}

.search-input-hero .accessibility-text {
  position: absolute;
  width: 0;
  overflow: hidden;
  opacity: 0;
}

.search-input-hero .search-input {
  padding: 28px 45px 28px 55px;
  border: 1px solid #ddd;
  border-radius: 50px;
  font-size: 14px;
  min-width: 660px;
  color: #58505f;
}

/* Complexity analysis */

.complexity-analysis {
  position: fixed;
  bottom: 30px;
  right: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  z-index: 99;
}

.complexity-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(25, 25, 25, 0.16);
  width: 140px;
  min-height: 80px;
}

.analysis-title {
  color: #333;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.analysis-result {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.complexity {
  font-size: 22px;
  font-weight: bold;
  color: #7d47e9;
}

.real-complexity {
  margin-top: 2px;
  font-size: 14px;
  color: #777;
}


/* Info page */
.chart-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.info-container .card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 370px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.info-container .card:hover {
  transform: translateY(-10px);
}

.info-container .card h3 {
  margin: 0 0 10px;
  font-size: 1.5em;
}

.info-container .card p {
  margin: 0;
  font-size: 1.2em;
  color: #555;
}


.info-container-2 {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.info-section {
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-section h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
}

.info-section p {
  line-height: 1.5;
}

.info-section ul {
  list-style-type: disc;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;
}

.info-section .formula {
  color: #333;
  background-color: #f7f5ff;
  text-align: center;
  display: flex;
  justify-content: center;
}

.info-section h3{
  margin-top: 30px;
  text-align: left;
}

strong{
  color:#333;
}

pre {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
}

/* src/components/ComplexityTable */

.table-container {
  margin: 20px auto;
  max-width: 800px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-container h3 {
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #e6e5f2;
}
