.stack-visualizer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 500px;
}

.stack {
    display: flex;
    flex-direction: column-reverse;
    padding: 10px 10px 5px;
    margin: 20px;
    border: #690f58 solid 1px;
    border-top: none;
    border-radius: 0 0 10px 10px;
    width: 150px;
    min-height: 200px;
    align-items: flex-start;
}

.result {
    margin-top: 20px;
    color: #4B0082; 
    text-align: center;
    padding: 10px 20px;
    /* border: 1px solid #d4c7f0;  */
    border-radius: 5px;
    background-color: hsl(240, 67%, 98%);
    min-width: 200px; 
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 4px rgba(75, 0, 130, 0.1); 
  }  

.pointer.top {
    flex-direction: row;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: -35px;
    margin-left: 160px;
    color: #4942E4;
    font-weight: bold;
}

.pointer span {
    padding: 0 5px;
}

.die {
    width: 150px;
    height: 50px;
    background-color: #FF3EA5;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    font-size: 20px;
    animation: slideIn-push 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.popped {
    animation: slideOut-pop 1s cubic-bezier(0, 0, 0.2, 1);
}

@keyframes slideIn-push {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        background-color: #711DB0;
        opacity: 1;
    }
}

@keyframes slideOut-pop {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-100px);
        background-color: #66347F;
    }
}