.side-panel-toggle {
  position: fixed;
  right: 60px;
  top: 0px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  padding-left: 20px;
  background: #fff;
  border: 1px solid #0d8bff;
  color: #2196f3;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

.side-panel-toggle:hover {
  color: #2196f3;
  background: #fff;
  box-shadow: 0 2px 4px rgba(25, 25, 25, .1);
}

.sidepanel-icon {
  color: #2196f3;
}

.side-panel-toggle.animate {
  animation: bounce 1s ease-in-out infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0) scale(1);
  }

  50% {
    transform: translateY(-5px) scale(1.005);
    box-shadow: 0 0 5px rgba(136, 169, 221, 0.6), 0 0 15px rgba(105, 121, 245, 0.2);
  }
}

.side-panel {
  position: fixed;
  top: 5px;
  right: 10px;
  width: 30%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(25, 25, 25, .16);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.side-panel.open {
  transform: translateX(0);
  height: 95vh;
  padding: 10px;
}

.side-panel-header {
  border-bottom: 1px solid #ededed;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 5px 15px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  background: transparent;
  border: none;
  height: 11px;
  min-width: 11px;
  margin: 20px 15px 0 0;
  padding: 0;
  cursor: pointer;
  fill: #191919;
  opacity: .5;
}

.close-btn:hover {
  background: transparent;
  opacity: 1;
}

.buttons-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 0;
  flex: 1;
  margin: auto;
  margin-top: 50px;
  width: 100%;
  z-index: 5;
}

.buttons-row button {
  display: flex;
  width: 100%;
  border: none;
  background-color: #2196f3;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-items: center;
  justify-content: center;
}

.buttons-row button:hover {
  background-color: #0d8bff;
}

.panel-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
  color: #555;
  /* margin-top: -30px; */
}

.panel-content .step {
  width: 100%;
  margin: 0;
  padding: 10px 0;
}


/* Media query for smaller screens */
@media (max-width: 768px) {
  .side-panel {
    width: 40%;
  }
}