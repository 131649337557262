.linkedlist {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 120px;
}

.list {
  display: flex;
  align-items: flex;
}

.list-node {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.box {
  border: 2px solid #1363DF;
  background-color: #FFF7FC;
  color: #1363DF;
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: relative;
}

.data {
  min-width: 60px;
}

.next,
.prev {
  padding: 17px 10px;
  background-color: #5999cd;
  color: #FFF7FC;
}

.head,
.null,
.tail {
  border: none;
  background-color: transparent;
  font-weight: bold;
  flex-direction: column;
}

.arrowIcons {
  display: flex;
  flex-direction: column;
  z-index: -1;
}

