.rect {
  background-color: steelblue;
  outline: 1px black;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin: 5px;
}

.bars,
nav {
  display: flex;
  flex-direction: row;
}

.bars{
  margin: 0 60px;
  justify-content: center;
}
